import { useEffect, useState } from 'react';
import { onValue, ref, set } from 'firebase/database';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { useLocation } from 'react-router';

import whiteFullLogo from '../../img/logo/white-full-logo.png';
import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';
import { onAuthStateChanged } from 'firebase/auth';

export const LowTicketAcademyNavbar = ()=>{

    let [student,setStudent] = useState(null);
    let [uid,setUID] = useState(null)

    let location = useLocation()

    let [darkMode,setDarkMode] = useState(false)
    let [processing,setProcessing] = useState(false)

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
        
          if(student){
            
            onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
              if(snapshot.exists()){
                setUID(student.uid)
                setStudent(snapshot.val());
                setProcessing(false);
              }else{
                
                if(window.location.pathname !== '/recover-enrollee'){
                  if(!window.location.pathname.includes('/enrollment') && !window.location.pathname.includes('/register') && !window.location.pathname.includes('/three-day-masterclass')){
                    window.location.href = '/recover-enrollee';
                  }
                }
                setProcessing(false);
              } 
            })
      
          }else setProcessing(false);
      
        })
      
        
    },[])

    useEffect(()=>{

        if(uid){

            onValue(ref(studentDB,`students/${uid}/theme`),(snapshot)=>{

            if(snapshot.exists()){
                if(snapshot.val() === 'DARK') setDarkMode(true)
                else setDarkMode(false)
            }else setDarkMode(false)

            })

        }else setDarkMode(false)

    },[uid])
    
    function toggleTheme(){
        setProcessing(true)
        set(ref(studentDB,`students/${uid}/theme`),darkMode?'LIGHT':'DARK').then(()=>{
            //window.location.reload()
            setProcessing(false)
        })
    }

    function logOut(){
        studentAuth.signOut().then(()=>window.location.href='/login');
    }

    
    return(
        <>
            <nav className="navbar sticky-top navbar-expand-lg bg-blue px-3 py-1 animate__animated animate__fadeInDown" style={{borderRadius:"0 0 15px 15px"}}>
                <div className="container-fluid">
                    <a className="navbar-brand mb-0" href="/">
                        <img src={whiteFullLogo} width="200px" alt="logo"/>
                    </a>

                    <div className="my-2" id="navbarText">
                        <span className="navbar-text">
                            <div className='row h-100 align-self-center'>

                                <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{gap:"20px"}}>
                                    <li className="nav-item dropstart">
                                        <p className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img style={{borderRadius:"20rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="36px"/>
                                            {student && <span className='ms-2 bold d-lg-inline d-none' style={{fontSize:'14px'}}>{student.profile.name.firstName} {student.profile.name.lastName}</span>}
                                        </p>

                                        <ul className="dropdown-menu" style={{position:'absolute'}} aria-labelledby="navbarDropdownMenuLink">
                                            <li><a className="dropdown-item pointer" href="/"><i className="fa-solid fa-arrow-trend-up"/> Modules</a></li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li><a className="dropdown-item pointer" href="/faq"><i className="fa-solid fa-circle-question"/> FAQ</a></li>
                                            <li><a className="dropdown-item pointer" href="/policies"><i className="fa-solid fa-shield-halved"/> Policies</a></li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li className="dropdown-item pointer" onClick={()=>toggleTheme()}><i className={`fa-solid ${darkMode?'fa-sun':'fa-moon'}`}/> {darkMode?'Light Mode':'Dark Mode'}</li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li onClick={()=>logOut()}><p className="dropdown-item pointer bold"><i className="fa-solid fa-right-from-bracket"/> Sign Out</p></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </span>
                    </div>

                </div>

            </nav>
        </>
    )
}