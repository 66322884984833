
import { Helmet } from 'react-helmet-async';

import moment from 'moment'
import { useEffect, useState } from 'react';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';

import refundSeal from '../../img/icon/refund-seal.png'
import $ from 'jquery'
import { getFileExt, hasCorrectEmail, hasLetter, hasNumber, hasUppercase, toTitleCase } from '../../helper/formatter';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { studentAuth, studentDB, studentStorage } from '../../api/firebase-student-config';
import {getDownloadURL, ref as sref, uploadBytes} from 'firebase/storage'
import { ref, set, push, get } from 'firebase/database';

import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';
import ModalCropper from '../../components/general/ModalCropper';
import ModalLoading from '../../components/general/ModalLoading';

import courseStandard from '../../img/funnel/course.png'

import { funnelDB } from '../../api/firebase-funnel-config';
import { adminDB } from '../../api/firebase-admin-config';

import JSConfetti from 'js-confetti';
import { coachBirthday } from '../../helper/globals';

export const Enrollment = (props) =>{


    useEffect(()=>{
        if(coachBirthday){
            const jsConfetti = new JSConfetti();
            jsConfetti.addConfetti({
                confettiNumber:2500,
                confettiRadius:3,
                confettiColors:["#0565C6","#01245A","#870B0B","#0565C6"]
            });
        }
    },[])

    const [pageID,setPageID] = useState()

    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('enrollment')

    },[pageID])

    var[hour,setHour] = useState(0)
    var[minute,setMinute] = useState(0)
    var[second,setSecond] = useState(0)

    useEffect(()=>{

        var eventTime= moment().add(1,'day').unix(); 
        var currentTime = moment(moment().month()+1 + '-' +moment().date()+ '-'+moment().year() ,'MM-DD-YY');
        var diffTime =  currentTime - eventTime;
        var duration = moment.duration(diffTime*1000, 'milliseconds');
        var interval = 1000;

        const inte = setInterval(() =>{
            duration = moment.duration(duration - interval, 'milliseconds');
            setHour(duration.hours() < 10?'0'+ String(duration.hours()):duration.hours())
            setMinute(duration.minutes() < 10?'0'+ String(duration.minutes()):duration.minutes())
            setSecond(duration.seconds() < 10?'0'+ String(duration.seconds()):duration.seconds())
        }, interval);

        return () => clearInterval(inte);

    },[])

    const [processing,setProcessing] = useState(false);

    const [step,setStep] = useState(1)

    const [firstName,setFirstName] = useState()
    const [lastName,setLastName] = useState()
    const [birthday,setBirthday] = useState()
    const [contactNumber,setContactNumber] = useState()
    const [discovery,setDiscovery] = useState()

    const [eaconomyAcc,setEaconomyAcc] = useState()

    const [rawImage,setRawImage] = useState()
    const [profilePicture,setProfilePicture] = useState()
    const [profilePictureURL,setProfilePictureURL] = useState()
    const [cropping,setCropping] = useState(false)

    const [emailAddress,setEmailAddress] = useState()
    const [password,setPassword] = useState()
    const [confirmPassword,setConfirmPassword] = useState()

    const [policyAgree,setPolicyAgree] = useState(false)

    function verifyFirstName(){

        var txtFirstName = $('#txtFirstName').val().trim();
        var errFirstName = $('#errFirstName');
        
        errFirstName.text('')
        setFirstName(null);

        if(txtFirstName.trim().length === 0) errFirstName.text('Blank spaces are not allowed.');
        else if(txtFirstName.length <= 1) errFirstName.text('Atleast 2 Characters.');
        else setFirstName(toTitleCase(txtFirstName));
        
    }

    function verifyLastName(){

        var txtLastName = $('#txtLastName').val().trim();
        var errLastName = $('#errLastName');
        
        errLastName.text('')
        setLastName(null);

        if(txtLastName.trim().length === 0) errLastName.text('Blank spaces are not allowed.');
        else if(txtLastName.length <= 1) errLastName.text('Atleast 2 Characters.');
        else setLastName(toTitleCase(txtLastName));

    }

    function verifyBirthday(){

        var txtBirthday = $('#txtBirthday').val();
        var errBirthday = $('#errBirthday');
        
        errBirthday.text('')
        setBirthday(null);

        if(txtBirthday.trim().length === 0) errBirthday.text('Select a Date.');
        else setBirthday(txtBirthday);


    }

    function verifyContactNumber(){

        var txtContactNumber = $('#txtContactNumber').val().trim();
        var errContactNumber = $('#errContactNumber');
        
        errContactNumber.text('')
        setContactNumber(null);

        if(txtContactNumber.trim().length === 0) errContactNumber.text('Blank spaces are not allowed.');
        else if(hasLetter(txtContactNumber)) errContactNumber.text('Letters are not allowed.');
        else if(txtContactNumber.length > 15) errContactNumber.text('Invalid Format.');
        else if(txtContactNumber.includes(' ')) errContactNumber.text('Spaces are not allowed')
        else setContactNumber(txtContactNumber);

    }

    function verifyDiscovery(){
        
        var slcDiscovery = $('#slcDiscovery').find(":selected").val();
        setDiscovery(slcDiscovery)
        
        setEaconomyAcc(null)
    }

    function verifyEAconomyAccount(){

        var txtEAconomyAccount = $('#txtEAconomyAccount').val().trim();
        var errEAconomyAccount = $('#errEAconomyAccount');
        
        errEAconomyAccount.text(' ')
        setEaconomyAcc(null)

        if(txtEAconomyAccount.length === 0){
            errEAconomyAccount.text("Don't leave blankspaces.")
            return;
        }

        get(ref(adminDB,`eaconomyAccounts/${txtEAconomyAccount}`)).then((snapshot)=>{
            if(snapshot.exists()){
                
                if(snapshot.val()) errEAconomyAccount.text("Account Already Enrolled")
                else{
                    errEAconomyAccount.text(' ')
                    setEaconomyAcc(txtEAconomyAccount)
                }

            }else{
                errEAconomyAccount.text("Invalid Account")
            }

        })

    }

    function verifyProfilePicture(){

        var fileProfilePicture = $("#fileProfilePicture").prop('files')
        var txtErr = $("#errProfilePicture")
    
        setProfilePicture(null)
        setProfilePictureURL(null)
        txtErr.text('')
    
        if(fileProfilePicture.length === 0) txtErr.text('Please select a file.')
        else if(getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'png' && getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'jpg' && getFileExt(fileProfilePicture[0].name).toLowerCase() !== 'jpeg') txtErr.text('PNG and JPG Files are the only accepted files.')
        else{
            var _URL = window.URL || window.webkitURL;

            var objectURL = _URL.createObjectURL(fileProfilePicture[0])

            setRawImage(objectURL)
            setCropping(true)

        }
        
    }

    function confirmProfilePicture(croppedImage){

        var txtErr = $("#errProfilePicture")
    
        setProfilePicture(null)
        setProfilePictureURL(null)
        txtErr.text('')

        setCropping(false)  

        var xhr = new XMLHttpRequest();
        xhr.open("GET", croppedImage);
        xhr.responseType = "blob";
        xhr.onload = function(){

            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);

            setProfilePicture(croppedImage)
            setProfilePictureURL(imageUrl)

        }
        xhr.send();

    }

    function cancelCrop(){
        $("#fileProfilePicture").val(null)
        setCropping(false)
        setProfilePicture(null)
        setProfilePictureURL(null)
        setRawImage(null)
    }

    function verifyEmailAddress(){

        var txtEmailAddress = $('#txtEmailAddress').val();
        var errEmailAddress = $('#errEmailAddress');
        
        txtEmailAddress = txtEmailAddress.trim()

        errEmailAddress.text('')
        //$('#infoEmailAddress').text('')  
        setEmailAddress(null);

        if(txtEmailAddress.trim().length === 0) errEmailAddress.text('Blank spaces are not allowed.');
        else if(!hasCorrectEmail(txtEmailAddress.trimEnd())) errEmailAddress.text('Invalid Email Format.');
        else setEmailAddress(txtEmailAddress);
        

    }

    function verifyPassword(){

        var txtPassword = $('#txtPassword').val();
        var errPassword = $('#errPassword');
        
        errPassword.text('')
        setPassword(null);

        if(txtPassword.trim().length === 0) errPassword.text('Blank spaces are not allowed.');
        else if(txtPassword.length < 8) errPassword.text('Atleast 8 characters.');
        else if(!hasUppercase(txtPassword)) errPassword.text('Should have atleast 1 uppercase letter.')
        else if(!hasNumber(txtPassword)) errPassword.text('Should have atleast 1 number.')
        else setPassword(txtPassword);

        verifyConfirmPassword()

    }

    function verifyConfirmPassword(){

        var txtPassword = $('#txtPassword').val();

        var txtConfirmPassword = $('#txtConfirmPassword').val();
        var errConfirmPassword = $('#errConfirmPassword');
        
        errConfirmPassword.text('')
        setConfirmPassword(null);

        if(txtConfirmPassword !== txtPassword) errConfirmPassword.text('Password does not match.')
        else setConfirmPassword(txtConfirmPassword);

    }
    
    function verifyAgree(){
        var aPP = $('#chkAgreePrivacyPolicy').is(":checked")
        var aTC = $('#chkAgreeTermsConditions').is(":checked")
        setPolicyAgree(aPP && aTC)
    }

    function registerUser(){

        setProcessing(true);

        createUserWithEmailAndPassword(studentAuth,emailAddress,password).then((student)=>{

            $('#leadForm').trigger('submit')

            fetch(profilePicture).then(r => r.blob()).then(blobFile => {

                var file = new File([blobFile], 'Hi', { type: blobFile.type })

                uploadBytes(sref(studentStorage,'students/profilePicture/' + student.user.uid), file).then((res)=>{
                    
                    getDownloadURL(sref(studentStorage,'students/profilePicture/' + student.user.uid)).then((url)=>{
                        
                        var registerDate = moment().format('MMMM DD, YYYY hh:mm:ss a')
    
                        const body = {
                            profile:{
                                name:{
                                    firstName:firstName,
                                    lastName:lastName
                                },
                                birthday:birthday,
                                contactNumber:contactNumber,
                                emailAddress:emailAddress,
                                profilePictureURL:url,
                                discovery:discovery,
                                enrollment:{
                                    registerDate:registerDate,
                                }
                            }
                        }

                        if(eaconomyAcc) body["eaconomyAccount"] = eaconomyAcc;
                        
    
                        set(ref(studentDB,'students/' + student.user.uid),body).then(()=>{  

                            if(eaconomyAcc){
                                set(ref(adminDB,`eaconomyAccounts/${eaconomyAcc}`),true).then(()=>{
                                    window.top.location.href = '/checkout';
                                })
                            }
                            else window.top.location.href = '/checkout';

                        }).catch((error)=>{
                            alert(error.message);
                            setProcessing(false);
                        })
    
    
                    }).catch((error)=>{
                        alert(error.message);
                        setProcessing(false);
                    })
    
                }).catch((error)=>{
                    alert(error.message);
                    setProcessing(false);
                })

            })
            
            
        }).catch((error)=>{
            alert(error.message);
            setProcessing(false);
        })

    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Enrollment</title>
                <script>
                    window.fbq("track", "AddToCart");
                </script>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn px-lg-2 px-4 py-4">



                <div className='row justify-content-center mt-lg-3 mt-0 text-center'>

                    <div className='col-12 text-center'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h3 className='black text-blue' style={{fontSize:'calc(1rem + 0.75vw)'}}>Complete Your Payment Before Bonuses Expire</h3>
                        
                        {coachBirthday && <h3 className='black text-danger animate__animated animate__pulse animate__infinite' style={{fontSize:'calc(0.85rem + 0.75vw)'}}><i className='fa fa-cake'></i> COACH ALEX'S BIRTHDAY SPECIAL DISCOUNT</h3>}
                    
                    </div>

                    <div className='col-12 mb-3'>
                        <div className='row justify-content-center' data-aos="fade-up">
                            <div className='col-12'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 mt-2'>
                                        <p className='text-muted saira bold text-center mb-1'>EXPIRES IN</p>
                                    </div>

                                    <div className='col-lg-10 text-center'>

                                        <h1 className='black' style={{fontSize:"calc(1rem + 2vw)"}}>
                                            {hour}
                                            <span className='saira text-light-blue me-lg-5 me-3' style={{fontSize:"calc(0.5rem + 1vw)"}}> HOURS</span>
                                            {minute}
                                            <span className='saira text-light-blue me-lg-5 me-3' style={{fontSize:"calc(0.5rem + 1vw)"}}> MINUTES</span>
                                            {second}
                                            <span className='saira text-light-blue' style={{fontSize:"calc(0.5rem + 1vw)"}}> SECONDS</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    
                    <div className='col-lg-5 funnel-container'>

                        <div className='row justify-content-center'>
                            <div className='col-lg-8 text-center align-self-center animate__animated animate__fadeInUp creds-form-container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 mb-3'>
                                        <h1 className='saira bold text-blue m-0'>Enroll Now</h1>
                                        <p className='saira'>Learn the secrets.</p>
                                    </div>

                                    <div className='col-12 mb-4'>
                                        <div className='row justify-content-center'>
                                            <div className='col-12 d-flex justify-content-center' style={{gap:"10px"}}>
                                                <div className={step===1?'dot active':'dot'}/>
                                                <div className={step===2?'dot active':'dot'}/>
                                                <div className={step===3?'dot active':'dot'}/>
                                            </div>
                                        </div>
                                    </div>

                                    

                                    <div className='col-12 p-0 animate__animated animate__fadeIn' style={{display:step===1?'':'none'}}>

                                        <div className='row p-lg-0 ps-4 pe-4'>

                                            <div className='col-lg-6 text-start mb-3'>
                                        
                                                <input placeholder='First Name' id="txtFirstName" onChange={()=>verifyFirstName()} type="text" className="form-control"/>
                                                <small id="errFirstName" className="form-text text-danger"></small>

                                            </div>

                                            <div className='col-lg-6 text-start mb-3'>
                                        
                                                <input placeholder='Last Name' id="txtLastName" onChange={()=>verifyLastName()} type="text" className="form-control"/>
                                                <small id="errLastName" className="form-text text-danger"></small>

                                            </div>

                                            <div className='col-12 text-start mb-3'>
                                        
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><span className='text-blue bold fa fa-phone'></span></span>
                                                    </div>
                                                    <input placeholder='Contact Number' id="txtContactNumber" onChange={()=>verifyContactNumber()} maxLength={15} type="text" className="form-control"/>
                                                </div>
                                                <small id="errContactNumber" className="form-text text-danger"></small>

                                            </div>

                                            <div className='col-12 text-start mb-3'>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="txtBirthday">Birthday</label>
                                                <input onChange={()=>verifyBirthday()} id="txtBirthday" type="date" className="form-control"/>
                                                <small id="errBirthday" className="form-text text-danger"></small>

                                            </div>

                                            <div className='col-12 text-start mb-4'>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="slcDiscovery">Where did you see Trading Circle?</label>
                                                <select className='form-select' id='slcDiscovery' onChange={()=>verifyDiscovery()}>
                                                    <option value=''>-- Select One --</option>
                                                    <option value='Facebook Ads'>Facebook Ads</option>
                                                    <option value='Facebook Group'>Facebook Group</option>
                                                    <option value='Forex Trading PH'>Forex Trading PH</option>
                                                    <option value='TikTok'>TikTok</option>
                                                    <option value='Instagram'>Instagram</option>
                                                    <option value='YouTube Ads'>YouTube Ads</option>
                                                    <option value='YouTube Main Channel'>YouTube Main Channel</option>
                                                    <option value='FX Robot Empire'>FX Robot Empire / EAconomy</option>
                                                    <option value='Friend Referral'>Friend Referral</option>
                                                </select>

                                            </div>

                                            {discovery === 'FX Robot Empire' &&
                                            <div className='col-12 text-start mb-3'>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="txtEAconomyAccount">FX Robot Empire / EAconomy Account #</label>
                                                <input id="txtEAconomyAccount" onChange={()=>verifyEAconomyAccount()} maxLength={15} type="text" className="form-control"/>
                                                <small id="errEAconomyAccount" className="form-text text-danger"></small>
                                                {eaconomyAcc && <small id="successEAconomyAccount" className="form-text text-success bold"> <i className='fa fa-check'/> Account Found (₱500 Discount Applied)</small>}

                                            </div>
                                            }

                                        </div>
                                        
                                    </div>

                                    <div className='col-12 p-0 animate__animated animate__fadeIn' style={{display:step===2?'':'none'}}>

                                        <div className='row p-lg-0 ps-4 pe-4'>

                                            <div className='col-12 text-start mb-3 justify-content-center'>
                                        
                                                <div className='row'>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                        <img src={profilePictureURL?profilePictureURL:profilePlaceholder} alt="profile" width="150px" style={{borderRadius:"100rem"}}/>
                                                    </div>
                                                    <small id="errProfilePicture" className="form-text text-danger text-center mb-3 mt-2"></small>
                                                </div>

                                                <label className='saira bold text-blue mb-1' htmlFor="fileProfilePicture">Profile Picture</label>
                                                <input id="fileProfilePicture" onChange={()=>verifyProfilePicture()} type="file" className="form-control" accept='image/*'/>

                                            </div>

                                        </div>
                                        
                                    </div>

                                    <div className='col-12 p-0 animate__animated animate__fadeIn mb-3' style={{display:step===3?'':'none'}}>

                                        <div className='row p-lg-0 ps-4 pe-4'>

                                            <div className='col-12 text-start mb-4  '>
                                        
                                                <label className='saira bold text-blue mb-1' htmlFor="txtEmailAddress">Email Address</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><i className='fa fa-user'/></span>
                                                    </div>
                                                    <input id="txtEmailAddress" onChange={()=>verifyEmailAddress()} type="text" className="form-control" placeholder="Enter Email"/>
                                                </div>
                                                <small id="errEmailAddress" className="form-text text-danger mb-3 mt-2"></small>
                                            </div>

                                            <div className='col-12 text-start mb-3'>
                                                
                                                <label className='saira bold text-blue mb-1' htmlFor="txtPassword">Password</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><i className='fa fa-lock'/></span>
                                                    </div>
                                                    <input id="txtPassword" onChange={()=>verifyPassword()} type="password" className="form-control" placeholder="Enter Password"/>
                                                </div>
                                                <small id="errPassword" className="form-text text-danger text-center mb-3 mt-2"></small>

                                            </div>

                                            <div className='col-12 text-start mb-3'>
                                                
                                                <label className='saira bold text-blue mb-1' htmlFor="txtConfirmPassword">Confirm Password</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><i className='fa fa-lock'/></span>
                                                    </div>
                                                    <input id="txtConfirmPassword" onChange={()=>verifyConfirmPassword()} type="password" className="form-control" placeholder="Enter Password Again"/>
                                                </div>
                                                <small id="errConfirmPassword" className="form-text text-danger text-center mb-3 mt-2"></small>

                                            </div>

                                            <div className='col-12 text-start mb-1 mt-2 ms-3'>
                                                <input onChange={()=>verifyAgree()} id='chkAgreePrivacyPolicy' className='me-2 pointer' type="checkbox"/>
                                                <label className='saira text-blue mb-1'>Agree to our <a href='/policies/privacy-policy' target="_blank" className='text-blue'><b><u>Privacy Policy</u></b></a></label>
                                            </div>

                                            <div className='col-12 text-start mb-2 ms-3'>
                                                <input onChange={()=>verifyAgree()} id='chkAgreeTermsConditions' className='me-2 pointer' type="checkbox"/>
                                                <label className='saira text-blue mb-1'>Agree to our <a href='/policies/terms-and-conditions' target="_blank" className='text-blue'><b><u>Terms & Conditions</u></b></a></label>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>

                                    <div className='col-12 p-lg-0 ps-4 pe-4'>
                                        {step === 1 &&
                                            <div>
                                            {firstName && lastName && birthday && contactNumber && (discovery !== "FX Robot Empire" || (discovery === "FX Robot Empire" && eaconomyAcc))?
                                                <button className='form-control saira m-0' onClick={()=>setStep(2)}>Next<i className='fa fa-arrow-right ms-2'/></button>
                                                :
                                                <button className='form-control saira m-0' disabled>Next<i className='fa fa-arrow-right ms-2'/></button>
                                            }   
                                            </div>
                                        }
                                        
                                        
                                        {step === 2 && 
                                        <div className='row'>

                                            <div className='col-2 pe-0'>
                                                <button className='form-control saira m-0' onClick={()=>setStep(1)}><i className='fa fa-arrow-left'/></button>
                                            </div>

                                            <div className='col-10'>
                                                {profilePicture?
                                                <button className='form-control saira m-0' onClick={()=>setStep(3)}>Next<i className='fa fa-arrow-right ms-2'/></button>
                                                :
                                                <button className='form-control saira m-0' disabled>Next<i className='fa fa-arrow-right ms-2'/></button>
                                                }
                                            </div>

                                        </div>
                                        }

                                        {step === 3 && 
                                        <div className='row'>

                                            <div className='col-2 pe-0'>
                                                <button className='form-control saira m-0' onClick={()=>setStep(2)}><i className='fa fa-arrow-left'/></button>
                                            </div>

                                            <div className='col-10'>
                                                {emailAddress && password && confirmPassword && policyAgree?
                                                <button className='form-control saira m-0' onClick={()=>registerUser()}><i className='fa fa-check me-1'/>Enroll</button>
                                                :
                                                <button className='form-control saira m-0' disabled><i className='fa fa-check me-1'/>Enroll</button>
                                                }
                                            </div>

                                        </div>
                                        }

                                    </div>

                                </div>

                            </div>
                        </div>

                        <img className='mt-4' src={refundSeal} alt='logo'/>

                        <div className='row justify-content-center my-4'>

                            <div className='col-lg-7 col-11 card-container py-5 px-5 text-start'>
                                <h5 className='black text-light-blue text-center saira mb-4'>Implementation Guarantee</h5>
                                <p className='mb-3 saira'><i className='fa fa-circle-check text-light-blue'/> Enroll Now in <b>Trading Circle Academy</b></p>
                                <p className='mb-3 saira'><i className='fa fa-circle-check text-light-blue'/> Implement the <b>CIRCLE Strategy</b> and everything you learned in 3 months</p>
                                <p className='mb-3 saira'><i className='fa fa-circle-check text-light-blue'/> Document you <b>experience</b>.</p>
                                <p className='saira'><i className='fa fa-circle-check text-light-blue'/> If you did not produce any results, I will <b>refund your enrollment fee 100%</b></p>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-5 funnel-container'>

                        <div className='row justify-content-center my-4'>

                            <div className='col-lg-9 col-11 card-container py-5 px-lg-5 px-4 text-start'>

                                <img className='mb-3' src={courseStandard} width='100%' alt='course logo'/>

                                <hr/>

                                <h1 className='text-light-blue text-center black mb-0'>STANDARD</h1>
                                <h5 className='bold text-center'>20x Your Opportunities</h5>
                                <h5 className='bold text-center small' style={{opacity:'0.5'}}>Build your foundation and learn the simplest way of trading.</h5>

                                <hr/>

                                <ul>
                                    <li className='mb-4'><b>TRADING CIRCLE LIFETIME ACCESS:</b> Get the entire course that you can access anytime so you can become a profitable Forex Trader. [Value: ₱49,997]</li>
                                    <li className='mb-4'><b>Full Course (A-Z Guide):</b> Get a step-by-step recorded modules to make sure you sail your journey smoothly and don’t get overwhelmed. [Value: ₱19,500]</li>
                                    <li className='mb-4'><b>Strictly guided by more coaches:</b> More coaches will help you to simplify and make your journey smooth and enjoyable. [Value: ₱25,997]</li>
                                    <li className='mb-4'><b>Mastermind Group Access:</b> Develop your Forex skills together with other students and top traders. [Value: ₱4,500]</li>
                                    <li className='mb-4'><b>Weekly Live Q and A:</b> Attend our weekly schedule and have all questions answered regarding the program. [Value: ₱9,997]</li>
                                    <li className='mb-4'><b>One-on-One Session:</b> Get a more personalized and exclusive training when you are in your live account already so coaches can assess you one by one. [₱49,500]</li>
                                    <li className='mb-4'><b>Signals and Analysis:</b> Receive a daily free signal and analysis from the top coaches when you are in your live account so you can earn already while still learning. [₱19,997]</li>
                                    <li className='mb-4'><b>Exclusive Merch:</b> Get free exclusive merch from Trading Circle when you pass the demo challenge as our gift and token for your perseverance. [₱1,500]</li>
                                    <li className='mb-4'><b> 24-hour Bonus #1:</b> Proven Strategy Tester: Track the accuracy of your trades and your win rate to make sure you that you will be profitable. [Value: ₱5,000]</li>
                                    <li className='mb-4'><b> 24-hour Bonus #2:</b> Trade Recorder: Keep track every trade to maximize your wins. This journal is a plug-and-play template that computes everything for you. [Value: ₱3,000]</li>
                                    <li className='mb-4'><b> 24-hour Bonus #3:</b> One Month Money Growth: Compound your first capital within one-month by using this template to guide you how to strategically increase trades. [Value: ₱7,000]</li>
                                </ul>
                                
                                
                                {!eaconomyAcc?
                                <>

                                    {coachBirthday?
                                    <>
                                        <h4 className='text-center black text-success animate__animated animate__pulse animate__infinite'><i className='fa fa-cake'></i> COACH ALEX'S BIRTHDAY SPECIAL DISCOUNT</h4>
                                        <p className='text-danger text-discount text-center'><del>Total Value: ₱ 195,998</del></p>
                                        <h2 className='text-center text-discount'><del>₱ 5,997 ONLY</del></h2>
                                        <h2 className='text-center text-light-blue'>Price Today: <b>₱ 4,497</b></h2>
                                        <hr/>
                                        <h3 className='text-center text-danger bold mt-4 animate__animated animate__headShake animate__infinite'>{hour}:{minute}:{second}<br/><b>LIMITED TIME ONLY</b></h3>
                                    </>
                                    :
                                    <>
                                        <p className='text-danger text-center'><del>Total Value: ₱ 195,998</del></p>
                                        <h2 className='text-center text-light-blue'>Price Today: <b>₱ 5,997 ONLY</b></h2>
                                    </>
                                    }

                                </>
                                :
                                <>
                                    <p className='text-danger text-center'><del>Total Value: ₱ 195,998</del></p>
                                    <h4 className='text-center text-danger'><del>Price Today: <b>₱ 5,997 ONLY</b></del></h4>
                                    <h3 className='text-center text-light-blue mb-0'><i className='fa fa-star'/> Privilege Discount</h3>
                                    <h3 className='text-center text-light-blue mt-0'><b>₱ 5,497 ONLY</b></h3>
                                </>
                                }

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <FunnelFooter/>

            <form method='POST' action="https://assets.mailerlite.com/jsonp/1234508/forms/141239502689535623/subscribe" id="leadForm" style={{display:'none'}} target="email_frame">

                <p className='bold text-blue mt-3'>Full Name</p>
                <input name='fields[name]' id="txtFullName" value={firstName} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Last Name</p>
                <input name='fields[last_name]' id="txtLastName" value={lastName} readOnly className='form-control text-muted' type="text"/>
    
                <p className='bold text-blue mt-3'>Email Address</p>
                <input name='fields[email]' id="txtEmailAddress" value={emailAddress} readOnly className='form-control text-muted' type="text"/>

                <input type='submit' value="Submit"/>
                <iframe name='email_frame' id='email_frame' className='d-none'/>
            </form>
            
            {/*
            <form method='POST' action="https://tradingcircle.activehosted.com/proc.php" id="leadForm" style={{display:'none'}} target="kartra_frame">
                
                {discovery !== 'FX Robot Empire'?
                <>
                    <input type="hidden" name="u" value="3" data-name="u"/>
                    <input type="hidden" name="f" value="3" data-name="f"/>
                    <input type="hidden" name="s" data-name="s"/>
                    <input type="hidden" name="c" value="0" data-name="c"/>
                    <input type="hidden" name="m" value="0" data-name="m"/>
                    <input type="hidden" name="act" value="sub" data-name="act"/>
                    <input type="hidden" name="v" value="2" data-name="v"/>
                </>
                :
                <>
                    <input type="hidden" name="u" value="21" />
                    <input type="hidden" name="f" value="21" />
                    <input type="hidden" name="s" />
                    <input type="hidden" name="c" value="0" />
                    <input type="hidden" name="m" value="0" />
                    <input type="hidden" name="act" value="sub" />
                    <input type="hidden" name="v" value="2" />
                    <input type="hidden" name="or" value="940d794a71cedb4f11462f4276446aef" />
                </>
                }

                <p className='bold text-blue mt-3'>Full Name</p>
                <input name='name' id="txtFullName" value={firstName + " " + lastName} readOnly className='form-control text-muted' type="text"/>
    
                <p className='bold text-blue mt-3'>Email Address</p>
                <input name='email' id="txtEmailAddress" value={emailAddress} readOnly className='form-control text-muted' type="text"/>

                <input type='submit' value="Submit"/>
            </form>

            */}

            
            <ModalCropper show={cropping} image={rawImage} onHide={()=>cancelCrop()} onCrop={(img)=>confirmProfilePicture(img)}/>   
            <ModalLoading show={processing} />

        </>
    );

}