
import { Helmet } from 'react-helmet-async';
import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import {Header} from '../../components/general/Header'
import { onValue, ref, set, update} from 'firebase/database';
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { adminDB } from '../../api/firebase-admin-config';

import $ from 'jquery'
import axios from 'axios'
import moment from 'moment'
import dragonpayLogo from '../../img/logo/dragonpay-logo.png'
import { numberWithCommas } from '../../helper/formatter';
import ModalConfirmation from '../../components/general/ModalConfirmation';

import {useSearchParams} from 'react-router-dom'
import uuid from 'react-uuid';
import ModalChangeEmail from '../../components/general/ModalChangeEmail';
import { globalDirectMethod, globalPaymentMethod } from '../../helper/globals';


export const Pricing = () =>{

    const [processing,setProcessing] = useState(false);

    var [uid,setUID] = useState(null);
    var [student,setStudent] = useState(null);
    var [studentObject,setStudentObject] = useState(null)

    var [paymentMethods,setPaymentMethods] = useState([])

    var [purchasing,setPurchasing] = useState(false)

    var [selectedIndex,setSelectedIndex] = useState(0)

    var [voiding,setVoiding] = useState(false)
    var [selectedTxnID,setSelectedTxnID] = useState()

    var [standardPayment,setStandardPayment] = useState()
    var [directURL,setDirectURL] = useState()

    var [searchParams] = useSearchParams()

    var [emailVerified,setEmailVerified] = useState(false)
    var [email,setEmail] = useState(null)
    var [sentVerification,setSentVerification] = useState(false)
    var [editingEmail,setEditingEmail] = useState(false)

    var [direct] = useState(globalDirectMethod)
    var [inclusion] = useState(globalPaymentMethod)

    var [migrationMode] = useState(true)

    var [preferredOffer, setPreferredOffer] = useState()

    useEffect(()=>{

        if(email && !student.level){

            setProcessing(true)
            
            onValue(ref(adminDB,'migration'),(snapshot)=>{
                if(snapshot.exists()){
                    
                    for(var i in snapshot.val()){

                        if(email === snapshot.val()[i].email){
                            
                            set(ref(studentDB,`students/${uid}/level`),snapshot.val()[i].membership).then(()=>{
                                
                            }).catch((error)=>{
                                console.log(error)
                            })
                            
                            break

                        }

                    }

                }
            })



        }

    },[email,uid,student])

    useEffect(()=>{
        setProcessing(true)
        
        if(paymentMethods.length <= 0){
            function getPaymentMethods(){
                axios.get('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/payment/method',
                    {headers:{
                        "X-Requested-With": "XMLHttpRequest",   
                        'content-type': 'application/json',
                        'accept': '*/*'
                    }
                }).then((res)=>{
                    
                    var d = res.data
                    var newPMs = []

                    var i = 0
                    for(var k in d){
                        
                        if(inclusion.includes(d[k].procId)){
                            d[k].index = i;
                            newPMs.push(d[k])
                            i++;
                        }
                    
                    }
                    
                    //console.log(newPMs)
                    
                    setPaymentMethods(newPMs)
                    setProcessing(false)

                }).catch((error)=>{
                    setProcessing(false)
                    console.log(error)
                })
            }

            getPaymentMethods()

        }else{
            setProcessing(false)
        }

    },[paymentMethods,inclusion])

    function changeSelectedPayment(){
        var index = $('#txtPaymentMethod').find(":selected").val();
        setSelectedIndex(index)
    }

    function getPaymentMethodByProcID(id){

        if(paymentMethods){
            for(var i in paymentMethods)
                if(paymentMethods[i].procId === id) return paymentMethods[i];
            
        }

    }

    function checkoutStandard(){

        setProcessing(true)

        var id = uuid()
        id = id.replace(/-/g, '').slice(0,-5);

        var txnid = 'STANDARD-'+id
        //console.log(txnid)

        axios.post('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/payment/standard',
        {
            TransactionID:txnid,
            Email:student.profile.emailAddress,
            Description:`Standard Payment for ${student.profile.name.firstName}  ${student.profile.name.lastName} (${uid})`,
            ProcId:paymentMethods[selectedIndex].procId
        },
        {
            headers:{
                "X-Requested-With": "XMLHttpRequest",   
                'Content-Type': 'application/json',
                "Accept": "*/*"
            }
        }
        ).then((res)=>{

            if(res && res.data){

                set(ref(studentDB,`students/${uid}/payment/${txnid.includes("STANDARD")?"standard":"elite"}`),{"txnid":txnid}).then(()=>{
                    
                    set(ref(studentDB,`students/${uid}/payment/url`),res.data.Url).then(()=>{

                        if(!direct.includes(paymentMethods[selectedIndex].procId)){
                            setProcessing(true)
                            $(document.body).append(`<iframe src="${res.data.Url}" id="dpay-payment" style="display:none;"/>`)
                            $('#dpay-payment').on('load',()=>{
                                //window.location.reload()
                            })
                        }else{
                            window.open(res.data.Url)
                            window.location.reload()
                        }

                    })
                    
                })

            }
            
        }).catch((error)=>{
            console.log(error)
            setProcessing(false)
        })

    }

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
            
            if(student){

                setEmail(student.email)
                setEmailVerified(student.emailVerified)
                setStudentObject(student)

              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{

                if(snapshot.exists()){
                    setUID(student.uid);
                    setStudent(snapshot.val());
                    setPreferredOffer(snapshot.val().profile.preferredOffer)

                    if(searchParams.toString().length === 0 && snapshot.val().level) window.location.href = '/'

                    if(searchParams.toString().length > 0 && ((!snapshot.val().level && !snapshot.val().payment) || snapshot.val().level)){

                        var obj = '{'
                        
                        for (const entry of searchParams.entries()) {
                            const [param, value] = entry;

                            if(param !== 'digest' && param !== 'message')
                                obj += `"${param}":"${value}",`
                            
                        }

                        obj = obj.slice(0,-1)
                        obj += '}'
                        obj = JSON.parse(obj)
                        
                        set(ref(studentDB,`students/${student.uid}/payment/${obj.txnid.includes("STANDARD")?"standard":"elite"}`),obj).then(()=>{
                            if(student.level) window.location.href = '/pricing'
                            else window.location.href = '/elite-pricing'
                        }).catch((error)=>{
                            console.log(error.message)
                        })

                    }else if(searchParams.toString().length > 0){
                        if(student.level) window.location.href = '/pricing'
                        else window.location.href = '/elite-pricing'
                    }
                    

                }

            })
        
            }else setProcessing(false);

        })

    },[searchParams])

    useEffect(()=>{

        if(student && student.payment && student.payment.standard){
        
            axios.get(`https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/payment/standard/txnID/${student.payment.standard.txnid}`,
            {
                headers:{
                    "X-Requested-With": "XMLHttpRequest",   
                    'content-type': 'application/json',
                    'accept': '*/*'
                }
            }
            ).then((res)=>{

                update(ref(studentDB,`students/${uid}/payment/standard`),{status:res.data.Status}).then(()=>{

                    console.log(res.data);
                    setStandardPayment(res.data)

                    onValue(ref(studentDB,`students/${uid}/payment/url`),(snapshot)=>{
                        if(snapshot.exists()){
                            setDirectURL(snapshot.val())
                            setProcessing(false)
                        }
                    }).catch((error)=>{
                        setProcessing(false)
                    })

                    

                }).catch((error)=>{
                    console.log(error.message)
                })
                
                
            }).catch((error)=>{
                setProcessing(false)
                console.log(error.message)
            })

        }

    },[student,uid])

    function logOut(){
        studentAuth.signOut().then(()=>{
            if(window.self === window.top) window.location.href='/login'
            else window.location.href='/register-funnel'
        })
    }

    function verifyPayment(){
        window.location.reload()
    }
    
    function prepareVoidingPayment(id){
        setSelectedTxnID(id)
        setVoiding(true)
    }

    function claimMembership(){

        setProcessing(true)
        
        if(standardPayment){
            
            if(standardPayment.Status === 'S'){

                var d = moment().format('MMMM DD, YYYY hh:mm:ss a')

                $('#standardForm').on('submit',()=>{

                    set(ref(studentDB,`students/${uid}/profile/enrollment/enrollmentDate`),d).then(()=>{

                        set(ref(studentDB,`students/${uid}/level`),'Standard').then(()=>{
                            window.location.href = '/'
                        }).catch((error)=>{
                            console.log(error.message)
                        })
                        
                    })

                })

                $('#standardForm').trigger('submit')

            }

        }

    }

    function voidPayment(){

        if(selectedTxnID){

            setProcessing(true)
            setVoiding(false)

            set(ref(studentDB,`students/${uid}/payment/${selectedTxnID.includes("STANDARD")?"standard":"elite"}`),{}).then(()=>{

                axios.get(`https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/payment/void/${selectedTxnID}`,
                {
                    headers:{
                        "X-Requested-With": "XMLHttpRequest",   
                        'content-type': 'application/json',
                        'accept': '*/*'
                    }
                }
                ).then((res)=>{
                    
                    setStandardPayment(null)
                    //window.location.reload()
                    setProcessing(false)
                    
                }).catch((error)=>{
                    setProcessing(false)
                    //window.location.reload()
                    console.log(error.message)
                })

            }).catch((error)=>{
                console.log(error.message)
            })

            

            

        }

    }

    function sendVerificationEmail(){

        setProcessing(true)

        sendEmailVerification(studentObject).then(()=>{
            setSentVerification(true)
            setProcessing(false)
        }).catch((error)=>{
            setProcessing(false)
            console.log(error.message)
        })

    }

    
    return(
        <>
            <Helmet>
                <title>Trading Circle - Setup</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Setup'/>

                <div className='row text-center mb-3'>
                    <div className='col-12'>
                        <div className='row justify-content-center'>
                            <div className='col-8'>
                                {!standardPayment && <img style={{borderRadius:"100rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="75px"/>}
                                <p className='mt-2 mb-3'>Hi, {student?<>{student.profile.name.firstName}</>:"There"} <small className='pointer' onClick={()=>logOut()} style={{fontSize:"12px",color:"var(--accent)"}}>(Logout)</small></p>
                                {!standardPayment && student && student.level === undefined && <p className='text-muted text-center' style={{fontSize:"14px"}}></p>}
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className='row justify-content-center mb-5'>

                    {!migrationMode?
                    <div className='col-lg-8 col-11'>

                        {standardPayment && paymentMethods.length > 0 && (standardPayment.Status !== 'V' && standardPayment.Status !== 'F')?
                        <div id='standard-receipt' className='row justify-content-center animate__animated animate__fadeIn payment-container' style={{position:'relative'}}>
                            <div className='col-lg-5 card-container p-4 text-center pricing-container'>
                                
                                {standardPayment.Status !== 'S' &&
                                <div className="dropdown item-action">
                                    <button className="text-dark pointer bg-transparent" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-gear text-blue"></i></button>
                                    
                                    <ul className="dropdown-menu saira" style={{transition:'0s'}}>
                                        <li>
                                            <p className="dropdown-item pointer" onClick={()=>verifyPayment()}>
                                                <i className="fa fa-refresh"></i> Verify Payment
                                            </p>
                                        </li>
                                        <li className='dropdown-divider'/>
                                        <li>
                                            <p className="dropdown-item pointer" onClick={()=>prepareVoidingPayment(standardPayment.TxnId)}>
                                                <i className="fa fa-ban"></i> Void Payment
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                }
                                
                                <h3 className='text-blue text-center saira'>Payment Status</h3>
                                <img className='my-4' style={{borderRadius:"100rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="95px"/>
                                <p className='text-center text-light-blue bold' style={{fontSize:'20px'}}>{student.profile.name.firstName} {student.profile.name.lastName}</p>
                                <p className='text-center bold text-blue' style={{fontSize:'24px'}}>{standardPayment.Currency} {numberWithCommas(standardPayment.Amount.toFixed(2))}</p>
                                <p className='text-center bold text-blue' style={{fontSize:'16px',opacity:'0.7'}}>{getPaymentMethodByProcID(standardPayment.ProcId)?getPaymentMethodByProcID(standardPayment.ProcId).longName:standardPayment.ProcId}</p>

                                <hr/>
                                <p className='text-center bold text-muted mb-0'>Ref. No. {standardPayment.RefNo}</p>                                        
                                <p className='text-center text-muted'>{moment(standardPayment.RefDate).format('MMM. DD YYYY, hh:mm a')}</p>
                                <p className='text-center text-muted mt-2 mb-2' style={{fontSize:'12px'}}>{standardPayment.Description}</p>

                                {direct.includes(standardPayment.ProcId)?
                                <a className='text-center text-light-blue small bold' href={`${directURL}`} target='_blank' rel='noreferrer'><p style={{textDecoration:'underline'}}>Settle Payment</p></a>
                                :
                                <a className='text-center text-light-blue small bold' href={`https://gw.dragonpay.ph/Bank/GetEmailInstruction.aspx?refno=${standardPayment.RefNo}`} target='_blank' rel='noreferrer'><p style={{textDecoration:'underline'}}>Payment Instructions</p></a>
                                }
                                <hr/>

                                <h5 className='text-muted bold'>STATUS</h5>
                                <h4 className='bold'>
                                    {(standardPayment.Status === 'P' || standardPayment.Status === 'U')&& <span className='text-pending'>PENDING</span>}
                                    {standardPayment.Status === 'S'&& <span className='text-success'>SUCCESS</span>}
                                </h4>
                                <hr/>
                                {(standardPayment.Status === 'P' || standardPayment.Status === 'U') &&
                                <button className='btn-solid form-control' onClick={()=>verifyPayment()}><i className='fa fa-refresh'/> Verify Payment</button>
                                }

                                {standardPayment.Status === 'S' &&
                                <>
                                    <hr/>
                                    
                                    {emailVerified? 
                                    <button className='btn-solid px-5 py-2' onClick={()=>claimMembership()}><i className='fa fa-check'/> Claim Membership</button>
                                    :
                                    <>
                                        <p className='bold text-blue'>Verify Email First</p>
                                        <p className='text-blue mb-2' style={{fontSize:'14px'}}>{email}<i className="fa fa-edit ms-1 pointer" onClick={()=>setEditingEmail(true)}></i></p>
                                        <button onClick={()=>sendVerificationEmail()} className='btn-solid px-5 py-2'><i className='fa fa-envelope me-2'/>Send Verification</button>
                                        <p className='text-blue mt-1 pointer' style={{textDecoration:'underline',fontSize:'14px'}} onClick={()=>window.location.reload()}>Re-Check Verification</p>
                                        {sentVerification && <p className='text-success mt-2' style={{fontSize:'14px'}}><i className='fa fa-check'/> Verification Sent</p>}

                                    </>
                                    }
                                </>
                                }

                                <p className='m-0 mt-3 bold' style={{fontSize:'16px'}}>Powered By <img src={dragonpayLogo} width='100px' alt='dp'/></p>
                            
                            </div>
                        </div>
                        :
                        student&&
                        <>
                            {/* START PAYMENT STANDARD w/ Website Demo*/}
                            {!purchasing &&
                            <div className='row justify-content-center'>
                                <div className='col-lg-4 card-container-blue p-4' style={{minWidth:"350px"}}>
                                    
                                    <p className='text-center light saira text-white' style={{textDecoration:"line-through"}}>₱195,998</p>
                                    <div className='row justify-content-center my-2'>
                                        <h1 className='text-center bold m-0 text-white px-5' style={{borderRadius:"25px",width:"fit-content"}}>₱4,997</h1>
                                    </div>
                                    <h5 className='text-center m-0 bold text-white'><i className="fa-solid fa-arrow-trend-up"></i> STANDARD</h5>

                                    <hr/>

                                    <div className='pricing-perks px-4'>

                                        <p className='mb-4 text-white' style={{fontSize:"14px"}}>For people who wants to take their strategies to the next level.</p>

                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Lifetime Access</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Free Course</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Standard Course</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Coach Guidance</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Mastermind Group Access</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Weekly Live Q and A</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> One-on-One Session</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Signals and Analysis</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> Exclusive Merch</p>
                                        <p className='mb-2 perk-item inverted'><i className='fa-solid fa-circle-check'/> 24-Hour Bonus</p>
                                    </div>

                                    <button className='form-control btn-inverted mt-5' onClick={()=>setPurchasing(true)}>Purchase</button>

                                </div>

                                <div className='col-lg-7 card-container p-4'>
                                    <div className='row h-100'>
                                        <div className='col-12 align-self-center'>
                                            <h2 className='text-center saira text-light-blue'>Website Demonstration</h2>
                                            
                                            <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/811686998?h=29dd568ba0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>                                            
                                            <hr/>
                                            
                                            <p className='p-3 text-muted'>*This video demonstrates what the website offers in order for you to learn forex, we will be using this platform to communicate and learn from the community and coaches.</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            }
                            {/* END PAYMENT STANDARD w/ Website Demo*/}

                            {/* START PAYMENT w/ Select Payment Method Standard*/}
                            {purchasing && paymentMethods.length > 0 &&
                                <div className='row justify-content-center animate__animated animate__fadeIn payment-container'>
                                    <div className='col-lg-5 card-container p-4 pricing-container'>
                                        <div className='row h-100'>
                                            <div className='col-12 align-self-center text-center'>
                                                <p className='text-center light saira text-light-blue' style={{textDecoration:"line-through"}}>₱195,998</p>
                                                <div className='row justify-content-center my-2'>
                                                    <h1 className='text-center bold m-0 text-light-blue px-5' style={{borderRadius:"25px",width:"fit-content"}}>₱4,997</h1>
                                                </div>
                                                <h5 className='text-center m-0 bold text-light-blue'><i className="fa-solid fa-arrow-trend-up"></i> STANDARD</h5>
                                                <hr/>

                                                <h6 className='text-center text-blue bold'>Payment Method</h6>

                                                <img className='mt-3 mb-4 card-container p-2' style={{borderRadius:'25px'}} src={paymentMethods[selectedIndex].logo} width='80%' alt='logo'/>
                                                <div className='row justify-content-center'>    
                                                    <div className='col-11'>
                                                        <select id='txtPaymentMethod' className='form-select' onChange={()=>changeSelectedPayment()}>
                                                        {paymentMethods.length > 0 &&
                                                        paymentMethods.map((pm)=>{
                                                            return(<option value={pm.index} key={pm.index}>{pm.longName}</option>)
                                                        })
                                                        }
                                                        </select>
                                                        <p className='text-muted text-justify mt-2 px-3' dangerouslySetInnerHTML={{__html: paymentMethods[selectedIndex].remarks}}/>
                                                    </div>

                                                    <div className='col-lg-10 col-11 mt-5'>
                                                        <button className='btn-solid form-control' onClick={()=>checkoutStandard()}><i className='fa fa-money'/> Check Out</button>
                                                    </div>
                                                </div>

                                                
                                                
                                                <p className='m-0 mt-4 bold' style={{fontSize:'16px'}}>Powered By <img src={dragonpayLogo} width='100px' alt='dp'/></p>
                                                <hr/>
                                                <p className='p-3 text-muted text-justify'>*Select your Payment Method, Ensure that your email is correct when you have been prompted for it.<br/><br/>*Follow the instructions of DragonPay and Pay the indicated Amount.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* END PAYMENT w/ Select Payment Method Standard*/}
                        </>
                        }


                    </div>
                    :
                    
                    <div className='col-lg-4 col-11 mt-3 card-container py-5'>
                        <h3 className='text-center text-blue mb-1'><i className='fa fa-lock'/> Waiting for Payment</h3>
                        <p className='text-center text-blue'>Please settle your payment by using the button below.</p>
                        <div className='row justify-content-center mt-4'>
                            <div className='col-8 col-lg-10 text-center'>
                                <a rel='noreferrer' href={`${preferredOffer?'three-day-masterclass-checkout':'/checkout'}`} target='_self'>
                                    <button className='btn-solid px-5 py-2'><i className='fa fa-money'/> Payment Page</button>
                                </a>
                                <p className='text-muted mt-2 small'>*If you already sent a proof of payment please wait for it to be processed.</p>
                            </div>
                        </div>
                    </div>

                    }

                </div>

            </div>
            
            {student &&
            <form method="post" id="standardForm" action="https://app.getresponse.com/add_subscriber.html" style={{display:'none'}}>
                
                <p className='bold text-blue mt-3'>Name</p>
                <input name='name' id="txtName" value={student.profile.name.firstName} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Email Address</p>
                <input name='email' id="txtEmailAddress" value={student.profile.emailAddress} readOnly className='form-control text-muted' type="text"/>
                
                <input type="hidden" name="start_day" value="0"/>
                <input type="hidden" name="campaign_token" value="r0wrV"/>
                <input type='submit'/>

            </form>
            }
            
            <ModalChangeEmail show={editingEmail} onHide={()=>setEditingEmail(false)}/>
            <ModalConfirmation show={voiding} onHide={()=>setVoiding(false)} onConfirm={()=>voidPayment()} message="Do you want to void your Pending Payment?"/>
            <ModalLoading show={processing} />
        </>
    );

}